* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  max-width: 600px;
  margin: 0 auto;
}

*:focus {
  outline: none;
}

img {
  border: none;
}

button {
  outline: none !important;
  border: 0;
}

@font-face {
  font-family: "helvetica-neue-lt-geo-roman";
  src: url("./assets/fonts/helvetica-neue-lt-geo-roman/helvetica-neue-lt-geo-roman.eot");
  src: url("./assets/fonts/helvetica-neue-lt-geo-roman/helvetica-neue-lt-geo-roman.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/helvetica-neue-lt-geo-roman/helvetica-neue-lt-geo-roman.woff2") format("woff2"),
    url("./assets/fonts/helvetica-neue-lt-geo-roman/helvetica-neue-lt-geo-roman.woff") format("woff"),
    url("./assets/fonts/helvetica-neue-lt-geo-roman/helvetica-neue-lt-geo-roman.ttf") format("truetype"),
    url("./assets/fonts/helvetica-neue-lt-geo-roman/helvetica-neue-lt-geo-roman.svg#helvetica-neue-lt-geo-roman") format("svg");
  src: url("./assets/fonts/helvetica-neue-lt-geo-roman/helvetica-neue-lt-geo-roman.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/helvetica-neue-lt-geo-roman/helvetica-neue-lt-geo-roman.woff2") format("woff2"),
    url("./assets/fonts/helvetica-neue-lt-geo-roman/helvetica-neue-lt-geo-roman.woff") format("woff"),
    url("./assets/fonts/helvetica-neue-lt-geo-roman/helvetica-neue-lt-geo-roman.ttf") format("truetype"),
    url("./assets/fonts/helvetica-neue-lt-geo-roman/helvetica-neue-lt-geo-roman.svg#helvetica-neue-lt-geo-roman") format("svg");
  src: url("./assets/fonts/helvetica-neue-lt-geo-roman/helvetica-neue-lt-geo-roman.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/helvetica-neue-lt-geo-roman/helvetica-neue-lt-geo-roman.woff2") format("woff2"),
    url("./assets/fonts/helvetica-neue-lt-geo-roman/helvetica-neue-lt-geo-roman.woff") format("woff"),
    url("./assets/fonts/helvetica-neue-lt-geo-roman/helvetica-neue-lt-geo-roman.ttf") format("truetype"),
    url("./assets/fonts/helvetica-neue-lt-geo-roman/helvetica-neue-lt-geo-roman.svg#helvetica-neue-lt-geo-roman") format("svg");
}

@font-face {
  font-family: "helvetica-neue-lt-geo-bold";
  src: url("./assets/fonts/helvetica-neue-lt-geo-bold/helvetica-neue-lt-geo-bold.eot");
  src: url("./assets/fonts/helvetica-neue-lt-geo-bold/helvetica-neue-lt-geo-bold.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/helvetica-neue-lt-geo-bold/helvetica-neue-lt-geo-bold.woff2") format("woff2"),
    url("./assets/fonts/helvetica-neue-lt-geo-bold/helvetica-neue-lt-geo-bold.woff") format("woff"),
    url("./assets/fonts/helvetica-neue-lt-geo-bold/helvetica-neue-lt-geo-bold.ttf") format("truetype"),
    url("./assets/fonts/helvetica-neue-lt-geo-bold/helvetica-neue-lt-geo-bold.svg#helvetica-neue-lt-geo-bold") format("svg");
  src: url("./assets/fonts/helvetica-neue-lt-geo-bold/helvetica-neue-lt-geo-bold.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/helvetica-neue-lt-geo-bold/helvetica-neue-lt-geo-bold.woff2") format("woff2"),
    url("./assets/fonts/helvetica-neue-lt-geo-bold/helvetica-neue-lt-geo-bold.woff") format("woff"),
    url("./assets/fonts/helvetica-neue-lt-geo-bold/helvetica-neue-lt-geo-bold.ttf") format("truetype"),
    url("./assets/fonts/helvetica-neue-lt-geo-bold/helvetica-neue-lt-geo-bold.svg#helvetica-neue-lt-geo-bold") format("svg");
  src: url("./assets/fonts/helvetica-neue-lt-geo-bold/helvetica-neue-lt-geo-bold.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/helvetica-neue-lt-geo-bold/helvetica-neue-lt-geo-bold.woff2") format("woff2"),
    url("./assets/fonts/helvetica-neue-lt-geo-bold/helvetica-neue-lt-geo-bold.woff") format("woff"),
    url("./assets/fonts/helvetica-neue-lt-geo-bold/helvetica-neue-lt-geo-bold.ttf") format("truetype"),
    url("./assets/fonts/helvetica-neue-lt-geo-bold/helvetica-neue-lt-geo-bold.svg#helvetica-neue-lt-geo-bold") format("svg");
}

/* Roboto */
@font-face {
  font-family: "Roboto";
  src: url("./assets/fonts/Roboto/Roboto-Bold.ttf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "Roboto";
  src: url("./assets/fonts/Roboto/Roboto-Bold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "Roboto";
  src: url("./assets/fonts/Roboto/Roboto-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Roboto";
  src: url("./assets/fonts/Roboto/Roboto-Medium.ttf") format("truetype");
  font-weight: 500;
}

/* Monserrat */
@font-face {
  font-family: "Montserrat";
  src: url("./assets/fonts/montserrat/Montserrat-Medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Montserrat";
  src: url("./assets/fonts/montserrat/Montserrat-Regular.ttf") format("truetype");
  src: url("./assets/fonts/montserrat/Montserrat-Regular.ttf") format("truetype");
  src: url("./assets/fonts/montserrat/Montserrat-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Montserrat";
  src: url("./assets/fonts/montserrat/Montserrat-Bold.ttf") format("truetype");
  font-weight: bold;
}

/* FiraGo */
@font-face {
  font-family: "FiraGo";
  src: url("./assets/fonts/FiraGo/FiraGO-Medium.otf") format("opentype");
  font-weight: 500;
}

@font-face {
  font-family: "FiraGo";
  src: url("./assets/fonts/FiraGo/FiraGO-Regular.otf") format("opentype");
  font-weight: 400;
}

@font-face {
  font-family: "FiraGo";
  src: url("./assets/fonts/FiraGo/FiraGO-Bold.otf") format("opentype");
  font-weight: bold;
}

@font-face {
  font-family: "Solarx";
  src: url("./assets/fonts//SolarX.ttf") format("opentype");
  font-weight: normal;
  color: #ffbf3d;
}

@font-face {
  font-family: 'Helvetica Neue LT Pro';
  src: url('./assets/fonts/helvetica-neue-lt-pro/HelveticaNeueLTPro-HvEx.ttf') format('truetype');
  font-weight: 900;
  font-family: 'Helvetica Neue LT Pro';
  src: url('./assets/fonts/helvetica-neue-lt-pro/HelveticaNeueLTPro-HvEx.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Noto Sans Georgian";
  src: url("./assets/fonts/Noto-sans-georgian/noto-sans-georgian-bold.ttf") format("truetype");
  src: url("./assets/fonts/Noto-sans-georgian/noto-sans-georgian-bold.ttf") format("truetype");
  src: url("./assets/fonts/Noto-sans-georgian/noto-sans-georgian-bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

#root {
  height: 100%;
  margin: 0;
}

.caps {
  font-family: "helvetica-neue-lt-geo-roman";
  font-feature-settings: "case" on;
}

.caps-bold {
  font-family: "helvetica-neue-lt-geo-bold";
  font-feature-settings: "case" on;
}

.regular {
  font-family: "helvetica-neue-lt-geo-roman";
  font-feature-settings: "case" off;
}

/* html {
  height: 100%;
}

@media screen and (min-device-width: 320px) and (max-device-width: 992px) and (orientation: landscape) {
  html {
    transform: rotate(-90deg);
    transform-origin: left top;
    width: 100vh;
    height: 100vw;

    overflow-x: hidden;
    position: absolute;
    top: 100%;
    left: 0;
  }
}

body {
  height: 100%;
  margin: 0;
  background: #0a0f24;
} */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root,
html,
body {
  height: 100%;
  margin: 0;
  overflow: hidden;
  scrollbar-color: #ffffff21 #373754;
  scrollbar-width: thin;
}

body {
  background: #192441;
  margin: 0 auto;
}

@media (max-width: 992px) {
  html,
  #root,
  body {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
  }
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #373754;
}

::-webkit-scrollbar {
  height: 4px;
  width: 6px;
  background-color: #08081c;
}

::-webkit-scrollbar-thumb {
  background-color: #ffffff21;
  border-radius: 0;
}

.container,
.container-sm {
  width: 100%;
  box-sizing: border-box;
  padding: 0 8px;
}

a {
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  color: #ffffff;
}

a,
a:hover {
  cursor: pointer;
  text-decoration: none;
  color: #ffffff;
}

a:focus {
  outline: none;
  text-decoration: none;
}

a:active {
  text-decoration: none;
}

.clearfix:before,
.clearfix:after {
  display: block;
  content: "";
  clear: both;
}

.roboto-font {
  font-family: "Roboto", sans-serif;
}

.roboto-font {
  font-family: "Roboto", sans-serif;
}

body iframe {
  display: none;
}

#root,
html,
iframe,
body {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
}